import React from 'react';
import './Contact.css'

function Contact({ darkMode }) {

    return(
        <div>
            <div className="container">
                <div className="text-box">
                    <h2>EMAIL</h2>
                    <div classNameName="text-container">
                        <h1 classNameName="text">manovjain@gmail.com</h1>
                    </div>
                </div>
            </div>
        </div>
        
    );
    
}

export default Contact;