import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../src/Styles.css';
import Home from './Pages/Home/Home.jsx';
// import PhotoVideo from './Pages/PhotoVideo/PhotoVideo';
import Projects from './Pages/Projects/Projects';
import Contact from './Pages/Contact/Contact';
import AboutMe from './Pages/AboutMe/AboutMe';
import Navbar from './Components/Navbar/Navbar.jsx';


function App() {
  const [darkMode, setDarkMode] = useState(false);

  const handleToggleChange = () => {
    setDarkMode(!darkMode);
  };

  return (
    <Router>
       <div className={`app ${darkMode ? 'dark-mode' : ''}`}>
        <Navbar darkMode={darkMode} onToggleChange={handleToggleChange} />
        <Routes>
          <Route path="/" element={<Home darkMode={darkMode} />} />
          {/* <Route path="/photovideo" element={<PhotoVideo />} /> */}
          <Route path="/projects" element={<Projects darkMode={darkMode}/>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<AboutMe darkMode={darkMode} />} />
        </Routes>
      </div>
    </Router>
  );
}


export default App;
