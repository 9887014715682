import React from 'react';
import Project from '../../Components/Project/Project';
import './Projects.css';
import IconLink from '../../Components/IconLink/IconLink';
import anim from '../../Assets/anim.png'
import animWhite from '../../Assets/animWhite.png'
import animBlack from '../../Assets/animBlack.png'
import github from '../../Assets/github.svg'
import githubWhite from '../../Assets/githubWhite.svg'
import githubColor from '../../Assets/githubColor.svg'
import pokeNotion from '../../Assets/PokeNotion.svg'
//THIS NEEDS TO BE FIXED ^^^[[]]

import szn_white_bw from '../../Assets/szn/szn_white_bw.png'
import szn_white_color from '../../Assets/szn/szn_white_color.png'
import szn_black_bw from '../../Assets/szn/szn_black_bw.png'
import szn_black_color from '../../Assets/szn/szn_black_color.png'

function Projects({ darkMode }) {
  return (
    <div className="projects">
        <h1>PROJECTS</h1>
      <div className="container">
        <div className="project-grid">
          <Project
            leftContent={
                <h2>ANIM</h2>
            }
            rightContent={
                <IconLink
                    imageSrc={darkMode ? animWhite : animBlack}
                    iconSize={125}
                    hoverSrc={anim}
                    link="https://anim.llc"
                >
                </IconLink>
            }
          />
          <Project
            leftContent={
                <h2>GITHUB</h2>
              }
              rightContent={
                  <IconLink
                      imageSrc={darkMode ? githubWhite : github}
                      iconSize={125}
                      hoverSrc={githubColor}
                      link="https://github.com/ManovJain"
                  >
                  </IconLink>
              }
            />
            <Project
              leftContent={
                <h2>PokéNotion</h2>
              }
              rightContent={
                  <IconLink
                      imageSrc={pokeNotion}
                      iconSize={125}
                      hoverSrc={pokeNotion}
                      link="https://manovski.notion.site/Pok-Notion-5720abd089dd4beea8f26514a3676432"
                  >
                  </IconLink>
              }
            />
            <Project
              leftContent={
                <h2>In-SZN</h2>
              }
              rightContent={
                  <IconLink
                      imageSrc={darkMode ? szn_white_bw : szn_black_bw}
                      iconSize={125}
                      hoverSrc={darkMode ? szn_white_color : szn_black_color}
                      link="https://www.in-szn.com/"
                  >
                  </IconLink>
              }
            />
        </div>
      </div>
    </div>
  );
}

export default Projects;
