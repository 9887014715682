import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import './Planet.css';

const Planet = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    // Scene
    const scene = new THREE.Scene();

    // Sizes
    const sizes = {
      width: canvasRef.current.clientWidth,
      height: canvasRef.current.clientHeight,
    };

    // Hemisphere light
    const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x000000, 1);
    scene.add(hemisphereLight);

    // Camera
    const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100);
    camera.position.z = 20;
    scene.add(camera);

    // Renderer
    const canvas = canvasRef.current;
    const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.setClearColor(0x000000, 0); // Set the clear color to black and fully transparent
    renderer.render(scene, camera);

    // Controls
    const controls = new OrbitControls(camera, canvas);
    controls.enableDamping = true;
    controls.enablePan = false;
    controls.enableZoom = false;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 5;

    // Resize handler
    const resizeHandler = () => {
      const width = canvasRef.current.clientWidth;
      const height = canvasRef.current.clientHeight;
      sizes.width = width;
      sizes.height = height;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
    };

    window.addEventListener('resize', resizeHandler);

    // Render loop
    const loop = () => {
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(loop);
    };
    loop();

    // Load GLTF model
    const loader = new GLTFLoader();
    loader.load(`${process.env.PUBLIC_URL}/earth2.glb`,
      (gltf) => {
        const model = gltf.scene;
        scene.add(model);

        // Adjust position, rotation, and scale of the model as needed
        model.position.set(0, 0, 0);
        model.rotation.set(0, 0, 0);
        model.scale.set(3, 3, 3);

        // Create orbiting moon
        const moonGeometry = new THREE.SphereGeometry(0.65, 32, 32);
        const moonMaterial = new THREE.MeshLambertMaterial({ color: 0xffffff });
        const moon = new THREE.Mesh(moonGeometry, moonMaterial);
        scene.add(moon);

        // Moon orbit variables
        const orbitSpeed = 0.015;
        let orbitAngle = 0.5;

        // Update function for moon orbit
        const updateMoonOrbit = () => {
          const orbitRadius = 4;
          const orbitX = Math.cos(orbitAngle) * orbitRadius;
          const orbitZ = Math.sin(orbitAngle) * orbitRadius;
          moon.position.set(orbitX, 1, orbitZ);
          orbitAngle += orbitSpeed;
        };

        // Call update function in the render loop
        const renderLoop = () => {
          updateMoonOrbit();
          renderer.render(scene, camera);
          requestAnimationFrame(renderLoop);
        };

        renderLoop();
      },
      undefined,
      (error) => {
        console.error('An error happened while loading the model:', error);
      }
    );

    return () => {
      window.removeEventListener('resize', resizeHandler);
      renderer.dispose();
    };
  }, []);

  return <canvas className="webgl" ref={canvasRef}></canvas>;
};

export default Planet;
