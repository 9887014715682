import React from 'react';
import '../Home/Home.css'
import IconLink from '../../Components/IconLink/IconLink';
import resume from '../../Assets/resume.svg';
import resumeWhite from '../../Assets/resumeWhite.svg';
import resumeColor from '../../Assets/resumeColor.svg';

function AboutMe({ darkMode }) {

    return(
        <div>
            <div className="container">
                <div className="text-box">
                    <h2>CHECK OUT MY RESUME!</h2>
                    <div>
                    <IconLink
                            imageSrc={darkMode ? resumeWhite : resume}
                            iconSize={150}
                            hoverSrc={resumeColor}
                            link="https://manovski.notion.site/manovski/Manov-Jain-c56dff46e6d64623899d8db512b29d45"
                        >
                        </IconLink>
                    </div>
                </div>
            </div>
        </div>
        
    );
    
}

export default AboutMe;