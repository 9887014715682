import React from 'react';
import PropTypes from 'prop-types';
import './Project.css';

const Project = ({ leftContent, rightContent }) => {
  return (
    <div className="project">
      <div className="left-section">{leftContent}</div>
      <div className="right-section">{rightContent}</div>
    </div>
  );
};

Project.propTypes = {
  leftContent: PropTypes.node.isRequired,
  rightContent: PropTypes.node.isRequired,
};

export default Project;
