import React, { useState } from 'react';

const IconLink = ({ imageSrc, iconSize, hoverSrc, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    window.open(link, '_blank'); // Opens the link in a new tab
  };

  const iconStyle = {
    width: iconSize,
    height: iconSize,
    marginLeft: '2vw', // Adjust the left margin as per your requirement
    marginRight: '2vw', // Adjust the right margin as per your requirement
    cursor: isHovered ? 'pointer' : 'auto',
  };

  return (
    <img
      src={isHovered ? hoverSrc : imageSrc}
      alt="Icon Link"
      style={iconStyle}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export default IconLink;
