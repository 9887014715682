import React from 'react';
import './Home.css'

import Planet from '../../Components/Planet/Planet';

function Home({ darkMode }) {

    return(
        <div>
            <div className="container">
                <div className="text-box">
                    <h1>HELLO WORLD</h1>
                </div>
                <Planet/>
            </div>
        </div>
        
    );
    
}

export default Home;