import React from 'react';
import PropTypes from 'prop-types';
import './NavIcon.css';

const NavIcon = ({ imageSrc, text, iconSize }) => {
  return (
    <div className="nav-icon">
      <img src={imageSrc} alt="Nav Icon" className="nav-icon-image" style={{ width: iconSize, height: iconSize }} />
      <span className="nav-icon-text" >
        {text}
      </span>
    </div>
  );
};

NavIcon.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  iconSize: PropTypes.number.isRequired,
};

export default NavIcon;
