import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Navbar.css';
import '../../Styles.css';
import NavIcon from '../NavIcon/NavIcon';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

import contact from '../../Assets/contact.svg'
import contactWhite from '../../Assets/contactWhite.svg'
// import camerasvg from '../../Assets/camerasvg.svg';
// import camerasvgWhite from '../../Assets/camerasvgWhite.svg';
import code from '../../Assets/code.svg';
import codeWhite from '../../Assets/codeWhite.svg';
import smiley from '../../Assets/smiley.svg'
import smileyWhite from '../../Assets/smileyWhite.svg'


const Navbar = ({ darkMode, onToggleChange }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sideMenuRef = useRef(null);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleDarkModeToggle = () => {
    onToggleChange(!darkMode);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <nav className={`navbar ${isMenuOpen ? 'menu-open' : ''}`}>
      <div className="navbar-logo">
        <NavLink exact to="/" className="nav-link">
          MANOVJAIN.COM
        </NavLink>
      </div>
      <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
        {/* <NavLink exact to="/photovideo" className="nav-link">
        <NavIcon
                imageSrc={darkMode ? camerasvgWhite : camerasvg}
                text="PHOTO/VIDEO"
                iconSize={25}
              />
        </NavLink> */}
        <NavLink exact to="/projects" className="nav-link">
        <NavIcon
                imageSrc={darkMode ? codeWhite : code}
                text="PROJECTS"
                iconSize={25}
              />
        </NavLink>
        <NavLink exact to="/contact" className="nav-link">
           <NavIcon
                imageSrc={darkMode ? contactWhite : contact}
                text="CONTACT ME"
                iconSize={25}
              />
        </NavLink>
        <NavLink exact to="/about" className="nav-link">
           <NavIcon
                imageSrc={darkMode ? smileyWhite : smiley}
                text="ABOUT ME"
                iconSize={25}
              />
        </NavLink>
        <DarkModeSwitch
          style={{ marginLeft: '2rem' }}
          checked={darkMode}
          onChange={handleDarkModeToggle}
          size={32}
        />
      </div>
      <div className={`navbar-hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </div>
      {isMenuOpen && (
        <div className="side-menu" ref={sideMenuRef}>
          <ul>
            <li>
              <NavLink exact to="/" className="side-menu-link" onClick={closeMenu}>
              MANOVJAIN.COM
              </NavLink>
            </li>
            <li>
              {/* <NavLink exact to="/photovideo" className="side-menu-link" onClick={closeMenu}>
              <NavIcon
                imageSrc={darkMode ? camerasvgWhite : camerasvg}
                text="PHOTO/VIDEO"
                iconSize={25}
              />
              </NavLink> */}
            </li>
            <li>
              <NavLink exact to="/projects" className="side-menu-link" onClick={closeMenu}>
              <NavIcon
                imageSrc={darkMode ? codeWhite : code}
                text="PROJECTS"
                iconSize={25}
              />
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/contact" className="side-menu-link" onClick={closeMenu}>
              <NavIcon
                imageSrc={darkMode ? contactWhite : contact}
                text="CONTACT ME"
                iconSize={25}
              />
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/about" className="side-menu-link" onClick={closeMenu}>
              <NavIcon
                imageSrc={darkMode ? smileyWhite : smiley}
                text="ABOUT ME"
                iconSize={25}
              />
              </NavLink>
            </li>
            <li>
            <DarkModeSwitch
              style={{ marginBottom: '2rem' }}
              checked={darkMode}
              onChange={handleDarkModeToggle}
              size={35}
            />
            </li>
          </ul>
          <button className="close-button" onClick={closeMenu}>
            CLOSE
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
